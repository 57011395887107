import React, { useEffect, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { Link } from "react-router-dom";
import { setModuleName, usersList } from "../../services/atoms";
import { Loading } from "../../components/shared/Loading";
import UsersList from "../../components/users/UsersList";
import RoleBased from "../../components/shared/RoleBased";
import { notCustomer } from "../../validationSchemas/validateModules";
import { Button } from "antd";
import { Plus } from "lucide-react";
import { SearchBar } from "../../components/shared/SearchBar";

export const Users = () => {
  const users = useAtomValue(usersList);
  const setModule = useSetAtom(setModuleName);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);

  useEffect(() => {
    setModule("Usuarios");
  }, [setModule]);

  useEffect(() => {
    if (users.state === "hasData") {
      setFilteredUsers(users.data.data);
    }
  }, [users]);

  if (users.state === "loading") {
    return <Loading />;
  }

  if (users.state === "hasData") {
    return (
      <>
        <RoleBased allowedRoles={notCustomer}>
          <Link to="/create-user">
            <Button
              shape="circle"
              type="primary"
              icon={<Plus size={35} />}
              style={{
                width: 54,
                height: 54,
              }}
              className="fixed right-4 bottom-12 bg-[#fdd34e] hover:bg-[#fdb159] border-none flex items-center justify-center shadow-lg hover:scale-105 transition-transform z-30"
            />
          </Link>
        </RoleBased>
          <SearchBar
            searchKeys={["firstName", "lastName", "role"]}
            data={users.data.data}
            onSearchResults={(results) => setFilteredUsers(results)}
            placeholder="Buscar por nombre, apellido o rol..."
          />

          {filteredUsers.length > 0 ? (
            <UsersList users={filteredUsers} />
          ) : (
            <div style={{ textAlign: "center" }}>
              No hay usuarios disponibles
            </div>
          )}
      </>
    );
  }
  return null;
};
