import React from "react";
import { Operation } from "../../interfaces/models/Operation";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

interface Props {
  operation: Operation;
}
export const OperationDetailCustomer = ({ operation }: Props) => {
  return (
    <Card
      title={
        <div className="flex items-center gap-2 text-[#112752]">
          <Link to="/operations" className="text-black">
            <FaArrowLeft fontSize={20} />
          </Link>
          <span>Detalle de Operación</span>
        </div>
      }
      className="shadow-md rounded-xl border-none"
    >
      <div className="grid grid-cols-2 px-4 pt-2 mb-10">
        <DetailRow label="Cliente">
          <div>{operation.customerName} </div>
        </DetailRow>

        <DetailRow label="Referencia">{operation.id}</DetailRow>

        <DetailRow label="Fecha de Actualización">
          <div>
            {new Date(operation.updatedAt).toLocaleDateString("es-ES", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </div>
        </DetailRow>

        <DetailRow label="Usuario Responsable">
          <div>{operation.userName} </div>
        </DetailRow>

        <DetailRow label="Nombre">
          <div>{operation.name}</div>
        </DetailRow>

        <DetailRow label="Descripción">
          <div>{operation.description} </div>
        </DetailRow>

        <DetailRow label="Estado">
          {operation.status ? "Finalizada" : "Pendiente"}
        </DetailRow>
      </div>
    </Card>
  );
};

const DetailRow = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <>
    <div className="py-4 border-b border-gray-300 text-gray-500">{label}</div>
    <div className="py-4 border-b border-gray-300 text-sm">{children}</div>
  </>
);
