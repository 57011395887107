import { useEffect, useState } from "react";
import { Form, Input, Button, Select, Radio, Card } from "antd";
import { getAllCustomers } from "../../services/CustomersService";
import useAuth from "../../hooks/useAuth";
import { validatePassword } from "../../validationSchemas/creation";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const { Option } = Select;

interface UserFormProps {
  handleRequest: (values: any) => void;
  data: any | null;
  type: "update" | "add";
}

export const UserForm = ({ handleRequest, data, type }: UserFormProps) => {
  const [form] = Form.useForm();
  const [selectedRole, setSelectedRole] = useState<string>(data?.role || "");
  const [customers, setCustomers] = useState([]);
  const { auth } = useAuth();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await getAllCustomers(auth.accessToken);
        setCustomers(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCustomers();
  }, [auth.accessToken]);

  const handleRoleChange = (e: any) => {
    setSelectedRole(e.target.value);
  };

  const roleLabels: Record<string, string> = {
    CUSTOMER: "Cliente",
    ADMIN: "Administrador",
    PROVIDER: "Proveedor",
  };

  return (
    <>
      <Card
        title={
          <div className="flex items-center gap-2 text-[#112752]">
            <Link to="/users" className="text-black">
              <FaArrowLeft fontSize={20} />
            </Link>
            <span>
              {type == "add" ? "Crear Usuario" : "Detalles del Usuario"}
            </span>
          </div>
        }
        className="shadow-md rounded-xl border-none"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleRequest}
          initialValues={data}
          style={{ marginTop: 10 }}
        >
          {type === "add" && (
            <>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { type: "email", required: true, message: "Email inválido" },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                label="Contraseña"
                name="password"
                rules={[{ validator: validatePassword }]}
              >
                <Input.Password placeholder="Contraseña" />
              </Form.Item>
            </>
          )}

          <Form.Item
            label="Nombre"
            name="firstName"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input placeholder="Nombre" />
          </Form.Item>

          <Form.Item
            label="Apellido"
            name="lastName"
            rules={[{ required: true, message: "El apellido es obligatorio" }]}
          >
            <Input placeholder="Apellido" />
          </Form.Item>

          <Form.Item
            label="Rol"
            name="role"
            rules={[{ required: true, message: "Debe seleccionar un rol" }]}
          >
            <Radio.Group onChange={handleRoleChange}>
              {Object.entries(roleLabels).map(([value, label]) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          {selectedRole === "CUSTOMER" && (
            <Form.Item
              label="Cliente"
              name="customerId"
              rules={[
                { required: true, message: "Debe seleccionar un cliente" },
              ]}
            >
              <Select placeholder="Seleccione un cliente">
                {customers.map(({ id, companyName }) => (
                  <Option key={id} value={id}>
                    {companyName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-full">
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
