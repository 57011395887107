import React, { useEffect } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "../../services/UsersService";
import { notCustomer } from "../../validationSchemas/validateModules";
import RoleBased from "../shared/RoleBased";
import { getUsers, setModuleName, tokenAtom } from "../../services/atoms";
import { useAtomValue, useSetAtom } from "jotai";
import { OpenNotification } from "../shared/Notification";
import { User } from "../../interfaces/models/User";

interface UserCardListProps {
  users: User[];
}

const UsersList = ({ users } : UserCardListProps) => {
  const navigate = useNavigate();
  const token = useAtomValue(tokenAtom);
  const refreshUsers = useSetAtom(getUsers);
  const setModule = useSetAtom(setModuleName);

  useEffect(()=>{
    setModule("Usuarios")
  },[setModule])

  const handleCardClick = (id: any) => {
    navigate(`/user-detail/${id}`);
  };

  const handleDelete = async (id: any, event: React.MouseEvent) => {
    try {
      event.stopPropagation();
      const opResponse = await deleteUser(id, token);
      refreshUsers();
      OpenNotification("success", "bottomRight", opResponse.message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="space-y-3">
      {users.map((data) => (
        <div
          key={data.id}
          className="bg-white rounded-xl shadow-sm p-4"
          onClick={() => handleCardClick(data.id)}
        >
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-2">
              <span
                className="text-[#112752] font-medium"
                style={{
                  maxWidth: 210,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data.firstName + " " + data.lastName}
              </span>
              <span
                className={`text-xs px-2 py-0.5 rounded-full ${(() => {
                  switch (data.role) {
                    case "ADMIN":
                      return "bg-purple-100 text-purple-700";
                    case "CUSTOMER":
                      return "bg-orange-100 text-orange-700";
                    case "PROVIDER":
                      return "bg-teal-100 text-teal-700";
                    default:
                      return "bg-gray-100 text-gray-700";
                  }
                })()}`}
              >
                {(() => {
                  switch (data.role) {
                    case "ADMIN":
                      return "ADMINISTRADOR"; 
                    case "CUSTOMER":
                      return "CLIENTE"; 
                    case "PROVIDER":
                      return "PROVEEDOR"; 
                    default:
                      return "";
                  }
                })()}
              </span>
            </div>
            <RoleBased allowedRoles={notCustomer}>
              <Button
                type="text"
                danger
                size="small"
                icon={
                  <span
                    className="text-lg"
                    onClick={(event) => handleDelete(data.id, event)}
                  >
                    x
                  </span>
                }
              />
            </RoleBased>
          </div>
          <p className="text-gray-600 text-sm mb-1">
            Correo: <b>{data.email}</b>
          </p>
        </div>
      ))}
    </div>
  );
};

export default UsersList;
