import useAuth from "../../hooks/useAuth";
interface RoleBasedButtonProps {
  allowedRoles: string[];
  children: React.ReactNode;
}

const RoleBased = ({ allowedRoles, children }: RoleBasedButtonProps) => {
  const { auth } = useAuth();
  return allowedRoles.includes(auth.role) ? <>{children}</> : null;
};

export default RoleBased;
