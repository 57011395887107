import { SearchBar } from "../../components/shared/SearchBar";
import { Pagination } from "antd";
import { useAtomValue, useAtom, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/shared/Loading";
import {
  currentPage,
  operationsHistoryList,
  setModuleName,
} from "../../services/atoms";
import { useState, useTransition, useEffect } from "react";
import OperationsList from "../../components/operations/OperationsList";

export const OperationsHistory = () => {
  const operations = useAtomValue(operationsHistoryList);
  const navigate = useNavigate();
  const [filteredOperations, setFilteredOperations] = useState<any[]>([]);
  const [page, setPage] = useAtom(currentPage);
  const [isPending, startTransition] = useTransition();
  const setModule = useSetAtom(setModuleName);

  useEffect(() => {
    setModule("Historial de Operaciones");
  }, [setModule]);

  useEffect(() => {
    setPage(1);
  }, [setPage]);

  const handleCardClick = (id: any) => {
    navigate(`/operation-detail/${id}/operations-history`);
  };

  const handlePageChange = async (page: number) => {
    startTransition(() => {
      setPage(page);
    });
  };

  if (operations.state === "loading") {
    return <Loading />;
  }
  if (operations.state === "hasData") {
    return (
      <>
        <SearchBar
          searchKeys={["name", "description", "id", "customerName"]}
          data={operations.data.data}
          onSearchResults={(results) => setFilteredOperations(results)}
          placeholder="Buscar por nombre, referencia o cliente..."
        />

        {filteredOperations.length > 0 ? (
          <>
            <OperationsList
              operations={filteredOperations}
              handleCardClick={handleCardClick}
            />
            <div style={{ marginTop: 10 }}>
              <Pagination
                current={operations.data.page}
                pageSize={operations.data.pageSize}
                total={operations.data.totalCount}
                onChange={handlePageChange}
                style={{ textAlign: "right" }}
              />
            </div>
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            No hay operaciones disponibles
          </div>
        )}
      </>
    );
  }

  return null;
};
