import { useState } from "react";
import { Image, Button } from "antd";
import { deleteEvidence } from "../../services/OperationsService";
import useAuth from "../../hooks/useAuth";
import { DeleteOperationEvidence } from "../../interfaces/models/Operation";
import { OpenNotification } from "../shared/Notification";
import { EvidenceFile } from "../../interfaces/models/Operation";
import { notCustomer } from "../../validationSchemas/validateModules";
import RoleBased from "../shared/RoleBased";

interface MultiSelectImagesProps {
  images: EvidenceFile[];
  onDeleteComplete: (deletedIds: number[]) => void;
}

const MultiSelectImages = ({images, onDeleteComplete}: MultiSelectImagesProps) => {
  const [selectedImages, setSelectedImages] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const { auth } = useAuth();
  const operationId = images[0]?.operationId;
  const toggleSelect = (id: number) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((imgId) => imgId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDelete = async () => {
    if (selectedImages.length > 0) {
      const evidenceToDelete: DeleteOperationEvidence = {
        OperationId: operationId,
        EvidenceFilesIds: selectedImages,
      };
      setLoading(true);
      const response = await deleteEvidence(
        evidenceToDelete,
        auth?.accessToken
      );
      onDeleteComplete(selectedImages);
      setSelectedImages([]);
      setLoading(false);
      OpenNotification("success", "bottomRight", response?.message);
    }
  };

  return images.length == 0 ? (
    <div
      style={{
        textAlign: "center",
        margin: 18,
        fontSize: 13,
        minHeight: 80,
      }}
    >
      No hay evidencias
    </div>
  ) : (
    <div
      style={{
        margin: "10px 5px",
      }}
    >
      <div className="text-center">
        <RoleBased allowedRoles={notCustomer}>
          <Button
            onClick={handleDelete}
            disabled={loading}
            className={`delete-button ${
              selectedImages.length > 0 ? "visible" : ""
            }`}
          >
            {loading ? "Eliminando..." : "Eliminar"}
          </Button>
        </RoleBased>
      </div>

      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) =>
            console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        <div
          className="image-grid"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          {images.map((image) => (
            <div
              key={image.id}
              className={`image-container ${
                selectedImages.includes(image.id) ? "selected" : "deselected"
              }`}
            >
              <input
                type="checkbox"
                checked={selectedImages.includes(image.id)}
                onChange={() => toggleSelect(image.id)}
                className="checkbox"
              />
              <Image
                src={image.fileLocation}
                alt={image.fileName}
                style={{
                  height: "180px",
                }}
                className="image-item"
              />
            </div>
          ))}
        </div>
      </Image.PreviewGroup>
    </div>
  );
};

export default MultiSelectImages;
