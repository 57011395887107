import React, { useState } from "react";
import { Layout, Input, Button, Form } from "antd";
import { UserCircle, Lock } from "lucide-react";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { tokenAtom } from "../services/atoms";
import useAuth from "../hooks/useAuth";
import { jwtDecode } from "jwt-decode";
import { getUser } from "../services/UsersService";
import { facebook, google, login } from "../services/AuthService";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { FaFacebook } from "react-icons/fa";
import { Loading } from "../components/shared/Loading";

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [_, setToken] = useAtom(tokenAtom);

  const handleAuth = async (authFunction: any, token: any) => {
    try {
      const { data } = await authFunction(token);
      const decoded = jwtDecode(data) as { id: string; role: string };
      const userData = await getUser(decoded.id, data);
      setAuth({
        accessToken: data,
        id: decoded.id,
        role: decoded.role,
        getAuth: true,
        user: userData.data,
      });
      setToken(data);
      navigate("/home");
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const onLogin = async (values: any) => {
    setLoading(true);
    await handleAuth(() => login(values.username, values.password), null);
  };

  const onFacebookLogin = (response: any) => {
    setLoading(true);
    handleAuth(facebook, response.accessToken);
  };

  const onGoogleLogin = (response: any) => {
    setLoading(true);
    handleAuth(google, response.credential);
  };

  return loading ? (
    <Loading />
  ) : (
    <Layout className="min-h-screen bg-[#F5F7FA] flex items-center justify-center">
      <div className="w-full max-w-md sm:max-w-lg mx-auto flex flex-col items-center justify-center min-h-screen p-4">
        <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100 w-full max-w-lg flex justify-center">
          <div className="w-full max-w-sm">
            <div className="text-center mb-6">
              <div className="flex justify-center">
                <img
                  className="w-48 sm:w-56 md:w-64"
                  src="/logo-evidence-app-large.png"
                  alt="Logo"
                />
              </div>
              <h2 className="text-lg sm:text-xl font-medium text-primary">
                Bienvenido
              </h2>
              <p className="text-xs sm:text-sm text-gray-500 mt-1">
                Ingresa tus credenciales para continuar
              </p>
            </div>

            <Form
              form={form}
              onFinish={onLogin}
              layout="vertical"
              requiredMark={false}
            >
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Por favor ingresa tu correo" },
                ]}
              >
                <Input
                  prefix={<UserCircle className="text-secondary" size={18} />}
                  placeholder="Correo electrónico"
                  className="h-10 sm:h-11 rounded-lg"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa tu contraseña",
                  },
                ]}
              >
                <Input.Password
                  prefix={<Lock className="text-secondary" size={18} />}
                  placeholder="Contraseña"
                  className="h-10 sm:h-11 rounded-lg"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-full h-10 sm:h-11 bg-accent hover:bg-warning text-primary font-medium text-base rounded-lg shadow-sm"
                >
                  Ingresar
                </Button>
              </Form.Item>

              <div className="text-center">
                <a
                  href="/new-pass-request"
                  className="text-secondary hover:text-primary text-xs sm:text-sm"
                >
                  ¿Olvidaste tu contraseña?
                </a>
              </div>
              <div className="flex justify-center mt-4 sm:mt-5">
                <GoogleLogin
                  type="icon"
                  onSuccess={onGoogleLogin}
                  onError={() => console.log("Login Failed")}
                />
                <FacebookLogin
                  appId={process.env.REACT_APP_FB_APP_ID || ""}
                  onSuccess={onFacebookLogin}
                  onFail={(error) => console.log("Login Failed!", error)}
                  style={{
                    backgroundColor: "#4267b2",
                    color: "#fff",
                    fontSize: "14px",
                    padding: "10px",
                    width: "44px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                    marginLeft: "8px",
                  }}
                >
                  <FaFacebook style={{ fontSize: 23 }} />
                </FacebookLogin>
              </div>

              <div className="flex flex-col items-center justify-center mt-4 sm:mt-5 text-center">
                <p className="text-xs mb-2">Desarrollado por:</p>
                <a
                  href="https://www.planbsys.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="w-20 sm:w-24"
                    src="/logo-planb-large.png"
                    alt="LogoPlanB"
                  />
                </a>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
