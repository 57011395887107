import { Form, Input, Button, Descriptions, Card } from "antd";
import { validatePassword } from "../../validationSchemas/creation";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

interface UserFormProps {
  data: any | null;
  handleRequest: (values: any) => void;
}

export const ProfileForm = ({ data, handleRequest }: UserFormProps) => {
  const [form] = Form.useForm();
  return (
    data && (
      <>
        <Card
          title={
            <div className="flex items-center gap-2 text-[#112752]">
              <Link to="/" className="text-black">
                <FaArrowLeft fontSize={20} />
              </Link>
              <span>Detalles del Usuario</span>
            </div>
          }
          className="shadow-md rounded-xl border-none"
        >
          <Descriptions bordered>
            <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
            <Descriptions.Item label="Nombre">
              {data.firstName}
            </Descriptions.Item>
            <Descriptions.Item label="Apellido">
              {data.lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Rol">{data.role}</Descriptions.Item>
            {data.role === "CUSTOMER" && (
              <Descriptions.Item label="Rol">
                {data.customerName}
              </Descriptions.Item>
            )}
          </Descriptions>

          <Form
            style={{
              marginTop: 10,
            }}
            form={form}
            layout="vertical"
            onFinish={handleRequest}
          >
            <Form.Item
              label="Contraseña Actual"
              name="currentPassword"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password placeholder="Contraseña actual" />
            </Form.Item>

            <Form.Item
              label="Contraseña"
              name="password"
              rules={[{ required: true, validator: validatePassword }]}
            >
              <Input type="password" placeholder="Contraseña" />
            </Form.Item>

            <Form.Item
              label="Confirmar Contraseña"
              name="passwordConfirm"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("La contraseñas no son iguales")
                    );
                  },
                }),
              ]}
            >
              <Input type="password" placeholder="Confirmar Contraseña" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="w-full">
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </>
    )
  );
};
