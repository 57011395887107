import { CreateOperationForm } from "../../components/forms/CreateOperationForm";
import { useSetAtom } from "jotai";
import { setModuleName } from "../../services/atoms";
import { useEffect } from "react";
export const CreateOperation = () => {
  const setModule = useSetAtom(setModuleName);

  useEffect(() => {
    setModule("Operaciones");
  }, [setModule]);

  return (
    <>
      <CreateOperationForm />
    </>
  );
};
