import { startTransition, useEffect, useState } from "react";
import { Form, Select, Input, Switch, Card, Button } from "antd";
import { Operation } from "../../interfaces/models/Operation";
import { getAllUsers } from "../../services/UsersService";
import { getAllCustomers } from "../../services/CustomersService";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { SaveOutlined, MailOutlined } from "@ant-design/icons";

const { TextArea } = Input;

interface UpdateOperationFormProps {
  operation: Operation;
  handleUpdate: (values: any) => void;
  redirect: string;
  handleSendEvidence: (values: any) => void;
}

export const UpdateOperationForm = ({
  operation,
  handleUpdate,
  redirect,
  handleSendEvidence,
}: UpdateOperationFormProps) => {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();
  const { auth } = useAuth();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const cus = await getAllCustomers(auth?.accessToken);
      const usrs = await getAllUsers(auth?.accessToken);
      startTransition(() => {
        setCustomers(cus.data);
        const filteredUsers = usrs.data.filter(
          (user: any) => user.role !== "CUSTOMER"
        );
        setUsers(filteredUsers);
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        form="updateOperation"
        shape="circle"
        type="primary"
        htmlType="submit"
        icon={
          <SaveOutlined
            style={{
              fontSize: 24,
            }}
          />
        }
        style={{
          width: 54,
          height: 54,
          bottom: 110,
        }}
        className="fixed right-4 border-none flex items-center justify-center shadow-lg hover:scale-105 transition-transform z-30"
      />
      <Button
        shape="circle"
        type="primary"
        icon={
          <MailOutlined
            style={{
              fontSize: 24,
            }}
          />
        }
        style={{
          width: 54,
          height: 54,
        }}
        onClick={handleSendEvidence}
        className="fixed right-4 bottom-12 bg-[#fdd34e] hover:bg-[#fdb159] border-none flex items-center justify-center shadow-lg hover:scale-105 transition-transform z-30"
      />

      <Card
        title={
          <div className="flex items-center gap-2 text-[#112752]">
            <Link to={"/" + redirect || "/"} className="text-black">
              <FaArrowLeft fontSize={20} />
            </Link>
            <span>Detalle de Operación</span>
          </div>
        }
        className="shadow-md rounded-xl border-none"
      >
        <Form
          layout="vertical"
          className="space-y-1"
          form={form}
          onFinish={handleUpdate}
          id="updateOperation"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {customers && (
              <Form.Item
                name="customerId"
                label={
                  <span className="text-[#112752] font-medium">Cliente</span>
                }
                className="mb-2"
                initialValue={operation.customerId}
                rules={[{ required: true, message: "Seleccione un cliente" }]}
              >
                <Select
                  options={customers.map(({ id, companyName }) => ({
                    value: id,
                    label: companyName,
                  }))}
                  className="w-full"
                  placeholder="Seleccione un cliente"
                />
              </Form.Item>
            )}

            <Form.Item
              label={
                <span className="text-[#112752] font-medium">Referencia</span>
              }
              className="mb-2"
            >
              <div> {operation.id}</div>
            </Form.Item>

            <Form.Item
              label={
                <span className="text-[#112752] font-medium">
                  Fecha de Actualización
                </span>
              }
              className="mb-2"
            >
              <div> {new Date(operation.updatedAt).toLocaleDateString()}</div>
            </Form.Item>
            {users && (
              <Form.Item
                label={
                  <span className="text-[#112752] font-medium">
                    Usuario Responsable
                  </span>
                }
                className="mb-2"
                name="userId"
                initialValue={operation.userId}
                rules={[{ required: true, message: "Seleccione un usuario" }]}
              >
                <Select
                  options={users.map(({ id, firstName, lastName }) => ({
                    value: id,
                    label: `${firstName} ${lastName}`,
                  }))}
                  placeholder="Seleccione un usuario"
                  className="w-full"
                />
              </Form.Item>
            )}
          </div>

          <Form.Item
            label={<span className="text-[#112752] font-medium">Nombre</span>}
            className="mb-2"
            name="name"
            rules={[{ required: true, message: "Escriba un nombre" }]}
            initialValue={operation.name}
          >
            <Input
              placeholder="Nombre de la operación"
              className="rounded-lg"
            />
          </Form.Item>

          <Form.Item
            label={
              <span className="text-[#112752] font-medium">Descripción</span>
            }
            name="description"
            className="mb-4"
            rules={[{ required: true, message: "Escriba una descripción" }]}
            initialValue={operation.description}
          >
            <TextArea
              placeholder="Proporcione una descripción"
              className="rounded-lg"
              rows={4}
            />
          </Form.Item>
          <Form.Item
            label={
              <span className="text-[#112752] font-medium">
                Estado de la operación
              </span>
            }
            name="status"
            initialValue={operation.status}
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Finalizada"
              unCheckedChildren="Pendiente"
            />
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default UpdateOperationForm;
