import { UserForm } from "../../components/forms/UserForm";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { useEffect, useState } from "react";
import { createUser } from "../../services/UsersService";
import useAuth from "../../hooks/useAuth";
import { OpenNotification } from "../../components/shared/Notification";
import { getUsers, setModuleName } from "../../services/atoms";
import { useSetAtom } from "jotai";
import { Loading } from "../../components/shared/Loading";

export const CreateUser = () => {
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const refresh = useSetAtom(getUsers);
  const setModule = useSetAtom(setModuleName);

  useEffect(() => {
    setModule("Usuarios");
  }, [setModule]);

  const handleRequest = async (values: any) => {
    try {
      setLoading(true);
      const response = await createUser(auth.accessToken, values);
      OpenNotification("success", "bottomRight", response.message);
      refresh();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <UserForm handleRequest={handleRequest} data={null} type="add" />
    </>
  );
};
