import { FaLaptop, FaRegClock } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Card, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { setModuleName } from "../services/atoms";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

export const Home = () => {
  const setModule = useSetAtom(setModuleName);

  useEffect(() => {
    setModule("Inicio");
  }, [setModule]);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <Link
        to="/operations"
        className="bg-white p-5 flex rounded-md justify-center"
      >
        <Card
          key="operations"
          hoverable
          className="bg-white hover:shadow-lg transition-all duration-300 border-none"
        >
          <div className="flex flex-col items-center text-center gap-4 py-4">
            <div className="bg-[#112752] p-4 rounded-full">
              <FaLaptop size={32} className="text-[#9ed4d9]" />
            </div>
            <div>
              <h3 className="text-[#112752] text-xl font-semibold mb-2">
                Operaciones
              </h3>
              <p className="text-gray-600">Ver operaciones existentes</p>
            </div>
          </div>
        </Card>
      </Link>
      <Link
        to="/operations-history"
        className="bg-white p-5 flex rounded-md justify-center"
      >
        <Card
          key="op-history"
          hoverable
          className="bg-white hover:shadow-lg transition-all duration-300 border-none"
        >
          <div className="flex flex-col items-center text-center gap-4 py-4">
            <div className="bg-[#112752] p-4 rounded-full">
              <FaRegClock size={32} className="text-[#9ed4d9]" />
            </div>
            <div>
              <h3 className="text-[#112752] text-xl font-semibold mb-2">
                Historial de Operaciones
              </h3>
              <p className="text-gray-600">Ver historial de operaciones</p>
            </div>
          </div>
        </Card>
      </Link>
    </div>
  );
};
